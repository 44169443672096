import axios from "axios"
import api from "../api"

export default {
  namespaced: true,
  state: {
    results: {},
    isFetching: false,
    email: '',
    latestEvents: '',
    eventsBeforeEmailWasSent: '',
    step: 1,
    prompts: {
      prompt1: '',
      prompt2: '',
      prompt3: '',
      prompt4: '',
      prompt5: '',
      prompt6: ''
    },
    modal: {
      text: '',
      textarea: '',
      buttonText: ''
    },
    templates: {
      prompt2Modal: {
        text: `Simulate changes in user events between the 1st and 2nd email:`,
        textarea: `Here are the events we used for the first email. Edit them to simulate a change between the first and second email`,
        buttonText: `Launch`,
      },
      prompt3ModalStep1: {
        text: `Here are the events we used for the first email. Edit them to simulate a change between the first and second email.`,
        buttonText: `Next`,
      },
      prompt3ModalStep2: {
        text: `Here are the events defined for the second email. Edit them to simulate a change between the second and third email.`,
        buttonText: `Launch`,
      },
      prompt4Modal: {
        text: `Here are the events we used for the first email. Edit them to simulate a change between the first and second email`,
        buttonText: `Launch`,
      },
      prompt5Modal: {
        text: `Here are the events we used for the first email. Edit them to simulate a change between the first and second email`,
        buttonText: `Launch`,
      },
    },
    platformEvents: {}
  },
  mutations: {
    SET_STATE (state, {key, data}) {
      state[key] = data;
    },
    SET_RESULTS (state, data) {
      const desiredOrder = ['emailGenerated', 'enrichedData', 'apolloData', 'hubspotData', 'initialPrompt'];
      const sortedData = desiredOrder.reduce((acc, key) => {
        if (data.hasOwnProperty(key)) {
          acc[key] = data[key];
        }
        return acc;
      }, {});

      state.results = {
        ...state.results,
        ...sortedData
      };
    },
    SET_PROMPTS (state, value) {
      state.prompts = value;
    },
    UPDATE_PROMPT (state, {key, value}) {
      state.prompts[key] = value;
    },
    UPDATE_MODAL_TEXTAREA (state, value) {
      if (state.step === 3 && state.modal.buttonText === 'Launch') {
        state.eventsBeforeEmailWasSent = value;
        return
      }
      state.latestEvents = value;
    },
    SET_MODAL_TEXT (state, value) {
      state.modal = state.templates[value];
    },
    SET_PLATFORM_EVENTS (state, value) {
      state.platformEvents = value;
    }
  },
  actions: {
    async get_prompts ({commit}) {
      try {
        const {data} = await axios.get(`${api.personalized_emails.settings}/prompts`);
        commit('SET_PROMPTS', data);
      } catch (err) {
        console.log(err)
      }
    },
    async save_prompts ({state, commit}, key) {
      try {
        await axios.put(`${api.personalized_emails.settings}/prompts`, {[key]: state.prompts[key]});
      } catch (err) {
        console.log(err)
      }
    },
    async get_first_result ({state, commit}) {
      try {
        commit('SET_STATE', {key: 'isFetching', data: true});
        const { data } = await axios.post(`${api.personalized_emails.email}1`, {email: state.email});
        commit('SET_PLATFORM_EVENTS', data.platformEvents);
        commit('SET_STATE', {key: 'step', data: +state.step + 1});
        commit('SET_RESULTS', data);
        commit('SET_STATE', {key: 'isFetching', data: false});
      } catch (err) {
        console.log(err)
      }
    },
    async get_results ({state, commit}, key) {
      try {
        const emailEndpoint = state.step === 6
          ? '/api/onboarding/what-user-wants'
          : api.personalized_emails[`email${state.step}`];

        commit('SET_STATE', {key: 'isFetching', data: true});

        const requestData = state.step === 6
          ? {
            email: state.email,
            job_title: state.results.enrichedData?.job_title || ''
          }
          : {
            email: state.email,
            platformEvents: state.latestEvents || state.platformEvents
          };
        const { data } = await axios.post(emailEndpoint, {
          // latestEvents: state.latestEvents,
          // eventsBeforeEmailWasSent: state.eventsBeforeEmailWasSent,
          // initialPrompt: state.results.initialPrompt,
          // previousEmailSent: state.results.emailGenerated,
        });
        commit('SET_STATE', {key: 'step', data: +state.step + 1});
        commit('SET_RESULTS', data);
        commit('SET_MODAL', false, { root: true });
        commit('SET_STATE', {key: 'isFetching', data: false});
      } catch (err) {
        console.log(err)
      }
    },
  }
}